import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Progress,
  Row,
  TabContent,
  Table,
  TabPane,
  UncontrolledDropdown,
} from "reactstrap";
import toastr from "toastr"
import classnames from "classnames";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Moment from "moment";
import { Link } from "react-router-dom";
import MedicalDetails from "./MedicalDetails";

import {
  getmedicalDetailsForAKid,
  getPickAndDropDetailForKid,
  getVaccinationList,
  getAttendanceForAKid,
  getAttendanceForKidDaycare,
  updateAdditionalDetailsForAKid,
  setPickAndDropDetailForKid,
  saveMedicalDetailsForAKid,
} from "../../store/actions";

import Parse from "parse";
import FeeTab from "./feeTab";
import FormTab from "./formTab";
import AdmissionFormComponent from "./AdmissionFormComponent";
import AddEscortModal from "./AddEscortModal";
import CCTVComponent from "./CCTVComponent";
import GroupDetails from "./groupDetails";
import TripDetails from "./tripsDetails";

const OtherDetails = (props) => {
  const [activeTab, setactiveTab] = useState("2");
  
  const [escorts, escortsOps] = useState([]);
  const [vaccines, vaccineOps] = useState([]);
  const [attActiveTab, attActiveTabOps] = useState("1");
  const [startDate, attStartDateOps] = useState(new Date());
  const [schoolAttendance, schoolAttendanceOps] = useState([]);
  const [daycareAttendance, daycareAttendanceOps] = useState([]);
  const [daycareStemp, daycareStempOps] = useState({});
  const [loadAdmissionForm, setLoadAdmissionForm] = useState(false);
  const [showAddEscortModel, setShowAddEscortModel] = useState(false);
  const [selectedEscortObject, setSelectedEscortObject] = useState();
  const [isDeletingEscort, setisDeletingEscort] = useState(false);
  const [isDeleteEscortModalOpen, setisDeleteEscortModalOpen] = useState(undefined);
  const [activeCTGTab, setactiveCTGTab] = useState("1");
  
  useEffect(() => {
    props.getmedicalDetailsForAKid(props.kid.id);
    props.getPickAndDropDetailForKid(props.kid.id);
    props.getVaccinationList(props.kid.id);
  }, [props.kid]);

  useEffect(() => {
    props.getAttendanceForAKid(props.kid.id, startDate);
    props.getAttendanceForKidDaycare(props.kid.id, startDate);
  }, [props.kid, startDate, props.schoolId]);

 

  const openEscortModal = (parseObj) => {
    setShowAddEscortModel(true)
    if (parseObj) {
      setSelectedEscortObject(parseObj)
    } else {
      setSelectedEscortObject(undefined)
    }
  }

  const handleDeleteEscort = (parseObject) => {
    setisDeletingEscort(true)
    parseObject.set("Deleted", true)
    parseObject.save().then(
      (result) => {
        props.setPickAndDropDetailForKid([result])
        setisDeletingEscort(true)
        setisDeleteEscortModalOpen(undefined)
      },
      (error) => {
        setisDeleteEscortModalOpen(undefined)
        toastr.warning("Error while deleting Escort, please refresh and try again")
      }
    );
  }

  useEffect(() => {
    let escortsTemp = [];
    if (props.kid.parents.length > 0) {
      for (let i in props.kid.parents) {
        let temp = {};
        temp.id = props.kid.parents[i].id;
        temp.name = props.kid.parents[i].attributes.Name;
        temp.relation = props.kid.parents[i].attributes.Relation;
        temp.number = props.kid.parents[i].attributes.PhoneNumber;
        temp.photoUrl = props.kid.parents[i].attributes.Photo
          ? props.kid.parents[i].attributes.Photo._url
          : "";
        temp.parseObject = props.kid.parents[i]
        escortsTemp.push(temp);
      }
    }
    if (props.pickAndDropObj) {
      if (props.pickAndDropObj.length > 0) {
        for (let i in props.pickAndDropObj) {
          let temp = {};
          temp.id = props.pickAndDropObj[i].id;
          temp.name = props.pickAndDropObj[i].attributes.PersonName;
          temp.relation = props.pickAndDropObj[i].attributes.Relation;
          temp.number = props.pickAndDropObj[i].attributes.MobileNumber;
          temp.photoUrl = props.pickAndDropObj[i].attributes.Photo
            ? props.pickAndDropObj[i].attributes.Photo._url
            : "";
          temp.parseObject = props.pickAndDropObj[i]

          escortsTemp.push(temp);
        }
      }
    }
    if (escortsTemp.length > 0) {
      escortsOps(escortsTemp);
    }
  }, [props.kid, props.pickAndDropObj]);


  useEffect(() => {
    if (props.vaccinationDetails) {
      let mTemp = [];
      for (let i in props.vaccinationDetails.questions) {
        let stemp = {};
        stemp.id = props.vaccinationDetails.questions[i].id;
        stemp.name =
          props.vaccinationDetails.questions[i].attributes.vaccineName;
        stemp.month =
          props.vaccinationDetails.questions[i].attributes.vaccinationAgeMonths;
        stemp.administeredDate = "--/--/----";
        stemp.isChecked = false;
        for (let j in props.vaccinationDetails.completedVaccine) {
          if (
            props.vaccinationDetails.completedVaccine[j].attributes
              .vaccineId === props.vaccinationDetails.questions[i].id &&
            props.vaccinationDetails.completedVaccine[j].attributes.kidId == props.kid.id
          ) {
            stemp.vaccineId = props.vaccinationDetails.completedVaccine[j].id;
            stemp.isChecked = true;
            stemp.administeredDate = Moment(
              props.vaccinationDetails.completedVaccine[j].attributes
                .administeredDate
            ).format("DD-MMM-YYYY");
          }
        }
        mTemp.push(stemp);
      }
      if (mTemp.length > 0) {
        vaccineOps(mTemp);
      }
    }
  }, [props.vaccinationDetails]);

  useEffect(() => {
    let temp = [];
    for (let i in props.attendanceDetails) {
      let aTemp = {};
      aTemp.id = props.attendanceDetails[i].id;
      aTemp.date = Moment(props.attendanceDetails[i].createdAt).format(
        "DD-MMM-YYYY"
      );
      if (props.attendanceDetails[i].attributes.ReachedSchoolAt) {
        aTemp.reachedTime = Moment(
          props.attendanceDetails[i].attributes.ReachedSchoolAt
        ).format("hh:mm a");
        if (props.attendanceDetails[i].attributes.LeftSchoolAt) {
          aTemp.leftTime = Moment(
            props.attendanceDetails[i].attributes.LeftSchoolAt
          ).format("hh:mm a");
          if (
            props.attendanceDetails[i].attributes.LeftSchoolAt.getTime() <
            props.attendanceDetails[i].attributes.ReachedSchoolAt.getTime()
          ) {
            aTemp.totalTimeHr = "--";
            aTemp.totalTimeMin = "--";
          } else {
            let ms =
              props.attendanceDetails[i].attributes.LeftSchoolAt.getTime() -
              props.attendanceDetails[i].attributes.ReachedSchoolAt.getTime();
            let d, h, m, s;
            s = Math.floor(ms / 1000);
            m = Math.floor(s / 60);
            s = s % 60;
            h = Math.floor(m / 60);
            m = m % 60;
            d = Math.floor(h / 24);
            h = h % 24;
            aTemp.totalTimeHr = h < 10 ? "0" + h.toString() : h.toString();
            aTemp.totalTimeMin = m < 10 ? "0" + m.toString() : m.toString();
          }
        } else {
          aTemp.totalTimeHr = "--";
          aTemp.totalTimeMin = "--";
        }
      } else {
        aTemp.remarks = props.attendanceDetails[i].attributes.remarks;
        aTemp.totalTimeHr = "--";
        aTemp.totalTimeMin = "--";
      }
      if (props.attendanceDetails[i].attributes.escortId) {
        for (let j in escorts) {
          if (
            escorts[j].id === props.attendanceDetails[i].attributes.escortId
          ) {
            aTemp.pickUpBy = escorts[j].name;
            aTemp.relation = escorts[j].relation;
            break;
          }
        }
      }
      if (!aTemp.pickUpBy) {
        aTemp.pickUpBy = "--";
      }
      temp.push(aTemp);
    }
    schoolAttendanceOps(temp);
    if (props.daycareAttendanceDetails.FeePlans) {
      let timeSheet = [];
      let daycareFeeDetails = {
        feeDetail: {},
        mealseDetails: [],
        feeDisplayDetails: { totalTime: 0, extraTime: 0, extraAmount: 0 },
        totalAmount: 0,
      };
      let totalAmount = 0;
      let timediff = 0,
        timediffmin = 0;
      let extraAmountCalculate = true;
      for (let i in props.daycareAttendanceDetails.FeePlans) {
        if (props.daycareAttendanceDetails.FeePlans[i].attributes.type === 1) {
          let temp = {};
          temp.amount =
            props.daycareAttendanceDetails.FeePlans[i].attributes.amount;
          temp.extraAmtPerHour =
            props.daycareAttendanceDetails.FeePlans[
              i
            ].attributes.extraAmtPerHour;
          temp.noOfHours =
            props.daycareAttendanceDetails.FeePlans[i].attributes.noOfHours;
          temp.dueDate =
            props.daycareAttendanceDetails.FeePlans[i].attributes.dueDate;
          if (
            props.daycareAttendanceDetails.FeePlans[i].attributes.greacePeriod
          ) {
            temp.greacePeriod =
              props.daycareAttendanceDetails.FeePlans[i].attributes
                .greacePeriod / 60;
          } else {
            temp.greacePeriod = 0;
          }
          temp.interval =
            props.daycareAttendanceDetails.FeePlans[i].attributes.interval;
          totalAmount += parseFloat(
            props.daycareAttendanceDetails.FeePlans[i].attributes.amount
          );
          daycareFeeDetails.feeDetail = temp;
        } else {
          let temp = {};
          temp.amont =
            props.daycareAttendanceDetails.FeePlans[i].attributes.amount;
          temp.title =
            props.daycareAttendanceDetails.FeePlans[i].attributes.name;
          totalAmount += parseFloat(
            props.daycareAttendanceDetails.FeePlans[i].attributes.amount
          );
          daycareFeeDetails.mealseDetails.push(temp);
        }
      }
      for (let i in props.daycareAttendanceDetails.TimesheetEntries) {
        let temp = {};
        temp.date = Moment(
          props.daycareAttendanceDetails.TimesheetEntries[i].attributes
            .createdAt
        ).format("DD-MMM-YYYY");
        temp.checkinTime = Moment(
          props.daycareAttendanceDetails.TimesheetEntries[i].attributes
            .checkInTime
        ).format("hh:mm a");
        if (
          props.daycareAttendanceDetails.TimesheetEntries[i].attributes
            .checkOutTime
        ) {
          temp.checkOutTime = Moment(
            props.daycareAttendanceDetails.TimesheetEntries[i].attributes
              .checkOutTime
          ).format("hh:mm a");
          let tl =
            props.daycareAttendanceDetails.TimesheetEntries[i].attributes
              .checkOutTime;
          let ti =
            props.daycareAttendanceDetails.TimesheetEntries[i].attributes
              .checkInTime;
          let diff = tl.getTime() - ti.getTime();
          let hr = Math.floor(diff / 1000 / 60 / 60);
          diff -= hr * 1000 * 60 * 60;
          let min = Math.floor(diff / 1000 / 60);
          if (hr < 0) {
            hr = hr + 24;
          }
          temp.timeDiff =
            (hr <= 9 ? "0" : "") +
            hr +
            "Hr " +
            (min <= 9 ? "0" : "") +
            min +
            "Min";
          timediff = hr + min / 100;
        } else {
          temp.checkOutTime = "-- : --";
          temp.timeDiff = daycareFeeDetails.feeDetail.noOfHours + "Hr";
          timediff = daycareFeeDetails.feeDetail.noOfHours;
        }
        if (
          timediff <=
          daycareFeeDetails.feeDetail.noOfHours + temp.greacePeriod
        ) {
          daycareFeeDetails.feeDisplayDetails.totalTime = timediff;
        } else {
          daycareFeeDetails.feeDisplayDetails.totalTime +=
            daycareFeeDetails.feeDetail.noOfHours;
          daycareFeeDetails.feeDetail.extraTime +=
            timediff - daycareFeeDetails.feeDetail.noOfHours;
          if (props.selectedSchool.attributes.additionalSettings) {
            if (props.selectedSchool.attributes.additionalSettings[1] === 1) {
              extraAmountCalculate = false;
              totalAmount +=
                Math.ceil(
                  timediff -
                  daycareFeeDetails.feeDetail.noOfHours -
                  temp.greacePeriod
                ) * temp.extraAmtPerHour;
            } else {
              extraAmountCalculate = true;
            }
          }
        }
        let tempTime = daycareFeeDetails.feeDisplayDetails.totalTime % 1;
        if (tempTime > 0.6) {
          tempTime -= 0.6;
          daycareFeeDetails.feeDisplayDetails.totalTime = tempTime + 1;
        }
        tempTime = daycareFeeDetails.feeDetail.extraTime % 1;
        if (tempTime > 0.6) {
          tempTime -= 0.6;
          daycareFeeDetails.feeDetail.extraTime = tempTime + 1;
        }
        tempTime = daycareFeeDetails.feeDisplayDetails.totalTime % 1;
        daycareFeeDetails.feeDisplayDetails.totalTime =
          daycareFeeDetails.feeDisplayDetails.totalTime - tempTime;
        daycareFeeDetails.feeDisplayDetails.totalMin = Math.round(
          tempTime * 100
        );
        tempTime = daycareFeeDetails.feeDetail.extraTime % 1;
        daycareFeeDetails.feeDetail.extraTime =
          daycareFeeDetails.feeDetail.extraTime - tempTime;
        daycareFeeDetails.feeDetail.extraMin = Math.round(tempTime * 100);
        timeSheet.push(temp);
        if (extraAmountCalculate) {
          if (
            daycareFeeDetails.feeDetail.extraTime > 0 ||
            daycareFeeDetails.feeDetail.extraMin > 0
          ) {
            let tempAmount1 = 0;
            if (daycareFeeDetails.feeDetail.extraTime > 0) {
              tempAmount1 =
                (daycareFeeDetails.feeDetail.extraTime / 100) *
                temp.extraAmtPerHour;
              totalAmount += tempAmount1;
            }
            let tempAmount2 = 0;
            if (daycareFeeDetails.feeDetail.extraMin > 0) {
              tempAmount2 =
                daycareFeeDetails.feeDetail.extraMin * temp.extraAmtPerHour;
              totalAmount += tempAmount2;
            }
          } else {
            daycareFeeDetails.feeDisplayDetails.extraAmount = 0;
            totalAmount -= temp.extraAmtPerHour;
          }
        }
      }
      daycareFeeDetails.totalAmount = totalAmount;
      daycareAttendanceOps(timeSheet);
      daycareStempOps(daycareFeeDetails);
    }
  }, [props.attendanceDetails, escorts, props.daycareAttendanceDetails]);

  
  const ExampleCustomInput = ({ value, onClick }) => (
    <button className="btn btn-primary" onClick={onClick}>
      {value}
    </button>
  );
  

  const handleSaveMedicalDetails = (medicaldetails) => {
    props.saveMedicalDetailsForAKid(medicaldetails);
  }
  return (
    <Card>
      <CardBody>
        <Nav pills className="navtab-bg nav-justified d-print-none">
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: activeTab === "2",
              })}
              onClick={() => {
                setactiveTab("2");
              }}
            >
              <i className="bx bx-credit-card-front"></i> {props.t("Escorts")}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: activeTab === "3",
              })}
              onClick={() => {
                setactiveTab("3");
              }}
            >
              <i className="bx bx-plus-medical" /> {props.t("Medical")}
            </NavLink>
          </NavItem>
          {/* <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: activeTab === "4",
              })}
              onClick={() => {
                setactiveTab("4");
              }}
            >
              <i className="bx bx-test-tube"></i>
              {props.t("Immunization")}
            </NavLink>
          </NavItem> */}
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: activeTab === "5",
              })}
              onClick={() => {
                setactiveTab("5");
              }}
            >
              <i className="bx bx-calendar-week"></i> {props.t("Attendance")}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: activeTab === "6",
              })}
              onClick={() => {
                setactiveTab("6");
              }}
            >
              <i className="bx bxs-calculator"></i> {props.t("Fee")}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: activeTab === "1",
              })}
              onClick={() => {
                setactiveTab("1");
              }}
            >
              <i className="bx bx-book" /> {props.t("Admission Details")}
            </NavLink>
          </NavItem>

          {!props.kid.attributes.Deleted &&<NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: activeTab === "9",
              })}
              onClick={() => {
                setactiveTab("9");
              }}
            >
              <i className="bx bx-cctv m-1"></i>
              <i className="bx bx-group m-1"></i>
              <i className="bx bx-bus m-1"></i>
            </NavLink>
          </NavItem>}

          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: activeTab === "7",
              })}
              onClick={() => {
                setactiveTab("7");
              }}
            >
              <i className="bx bx-pencil"></i> {props.t("Other Forms")}
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1" className="p-3">
            {loadAdmissionForm ? (
              <AdmissionFormComponent
                schoolId={props.schoolId}
                enquiryId={
                  props.kid && props.kid.attributes.enquiryId
                    ? props.kid.attributes.enquiryId
                    : ""
                }
                kidId={props.kid ? props.kid.id : ""}
              ></AdmissionFormComponent>
            ) : (
              <Button
                color="primary"
                className="btn btn-primary"
                onClick={() => {
                  setLoadAdmissionForm(true);
                }}
              >
                Fetch All Details
              </Button>
            )}
          </TabPane>
          <TabPane tabId="2" className="p-3">
            {!props.kid.attributes.Deleted && <div className="d-flex justify-content-end mb-2">
              <Button
                color="primary"
                onClick={() => {
                  openEscortModal();
                }}
              >
                Add Escorts
              </Button>
            </div>}
            <Table className="table mb-0 table-borderless table-centered">
              <thead>
                <tr>
                  <th scope="col" style={{ width: "5rem" }}>
                    #
                  </th>
                  <th scope="col">{props.t("Name")}</th>
                  <th scope="col">{props.t("Relation")}</th>
                  <th scope="col">{props.t("Number")}</th>
                  {!props.kid.attributes.Deleted && <th scope="col">{props.t("Action")}</th>}
                </tr>
              </thead>
              <tbody>
                {escorts.map((escort, key) => (
                  <tr key={key}>
                    <td>
                      {!escort.photoUrl ? (
                        <div
                          className="avatar-sm mx-auto"
                          style={{ width: "3rem", height: "3rem" }}
                        >
                          <span
                            className={
                              "avatar-title rounded-circle bg-soft-primary text-primary h4"
                            }
                          >
                            {escort.name != undefined
                              ? escort.name.charAt(0).toUpperCase()
                              : ""}
                          </span>
                        </div>
                      ) : (
                        <div>
                          <img
                            className="rounded-circle img-fluid mx-auto d-block"
                            style={{ width: "3rem", height: "3rem" }}
                            src={escort.photoUrl}
                            alt=""
                          />
                        </div>
                      )}
                    </td>
                    <td>
                      <h5 className="text-truncate font-size-14">
                        {escort.name}
                      </h5>
                    </td>
                    <td>{escort.relation}</td>
                    <td>{escort.number}</td>
                    {!props.kid.attributes.Deleted && <td>
                      <UncontrolledDropdown>
                        {/* <DropdownToggle href="#" className="card-drop" tag="i">
                          <i className="mdi mdi-dots-horizontal font-size-18"></i>
                        </DropdownToggle> */}
                        <DropdownToggle
                          tag="button"
                          className="btn btn-secondary"
                        >
                          {props.t("Action")}
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                          // onClick={() => {
                          //   props.history.push(`/escortIdcard/${props.schoolId}/${escort.parseObject.className === "Parent" ? "P" : "E"}/${props.kid.id}/${escort.parseObject.id}`);
                          // }}
                          >
                            <Link
                              to={`/escortIdcard/${props.schoolId}/${escort.parseObject.className === "Parent"
                                ? "P"
                                : "E"
                                }/${props.kid.id}/${escort.parseObject.id}`}
                              target="_blank"
                            >
                              {props.t("Download ID")}
                            </Link>
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              openEscortModal(escort.parseObject);
                            }}
                          >
                            {props.t("Edit")}
                          </DropdownItem>
                          {escort.parseObject.className !== "Parent" && (
                            <DropdownItem
                              onClick={() => {
                                setisDeleteEscortModalOpen(escort);
                              }}
                            >
                              {props.t("Delete")}
                            </DropdownItem>
                          )}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </td>}
                  </tr>
                ))}
              </tbody>
            </Table>
          </TabPane>
          <TabPane tabId="3" className="p-3">

            <MedicalDetails
              kid={props.kid}
              kidMedicalObj={props.kidMedicalObj}
              handleSaveMedicalDetails={handleSaveMedicalDetails}
              vaccines={vaccines}
            ></MedicalDetails>


          </TabPane>
          <TabPane tabId="4" className="p-3"></TabPane>
          <TabPane tabId="5" className="p-3">
            <ul className="nav nav-tabs nav-tabs-custom">
              <NavItem>
                <NavLink
                  className={classnames({ active: attActiveTab === "1" })}
                  onClick={() => {
                    attActiveTabOps("1");
                  }}
                >
                  {props.t("Class")}
                </NavLink>
              </NavItem>
              {props.kid.attributes.daycareClassId && (
                <NavItem>
                  <NavLink
                    className={classnames({ active: attActiveTab === "2" })}
                    onClick={() => {
                      attActiveTabOps("2");
                    }}
                  >
                    {props.t("Daycare")}
                  </NavLink>
                </NavItem>
              )}
              <NavItem className="ml-auto" style={{ marginLeft: 'auto' }}>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => attStartDateOps(date)}
                  placeholderText="Select date"
                  dateFormat="MMM - yyyy"
                  showMonthYearPicker
                  customInput={<ExampleCustomInput />}
                />
              </NavItem>
            </ul>
            <TabContent activeTab={attActiveTab}>
              <TabPane tabId="1" className="p-3">
                {schoolAttendance.length === 0 && (
                  <h3 className="text-center mt-5 text-muted">
                    {props.t("No Record Found For Month ")}
                  </h3>
                )}
                {schoolAttendance.length > 0 && (
                  <Table className="table mb-0 table-bordered">
                    <thead>
                      <tr>
                        <th>{props.t("Date")}</th>
                        <th>{props.t("Check In Time")}</th>
                        <th>{props.t("Check Out Time")}</th>
                        <th>{props.t("Total Time")}</th>
                        <th>{props.t("Picked Up By")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {schoolAttendance.map((value, key) => (
                        <tr key={key}>
                          <td>{value.date}</td>
                          {value.remarks && (
                            <td colSpan="2" className="text-danger text-center">
                              {value.remarks}
                            </td>
                          )}
                          {!value.remarks && <td>{value.reachedTime}</td>}
                          {!value.remarks && <td>{value.leftTime}</td>}
                          <td>
                            {value.totalTimeHr}hr {value.totalTimeMin}min
                          </td>
                          <td>
                            {value.pickUpBy}
                            {value.relation && `(${value.relation})`}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </TabPane>
              <TabPane tabId="2" className="p-3">
                {props.daycareAttendanceDetails.length === 0 && (
                  <h3 className="text-center mt-5 text-muted">
                    {props.t("No Daycare Record Found For Month")}
                  </h3>
                )}
                {/* {props.daycareAttendanceDetails.length > 0 &&  */}
                <Row>
                  <Col lx="8">
                    <Card>
                      <CardBody>
                        <div className="table-responsive">
                          <Table className="table table-centered mb-0 table-nowrap">
                            <thead className="thead-light">
                              <tr>
                                <th>{props.t("Date")}</th>
                                <th>{props.t("Check In Time")}</th>
                                <th>{props.t("Check Out Time")}</th>
                                <th>{props.t("Total Time")}</th>
                                <th>{props.t("Picked Up By")}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {daycareAttendance.map((value, key) => (
                                <tr key={key}>
                                  <td>{value.date}</td>
                                  <td>{value.checkinTime}</td>
                                  <td>{value.checkOutTime}</td>
                                  <td>{value.timeDiff}</td>
                                  <td>xxxx</td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xl="4">
                    <Card>
                      <CardBody>
                        <CardTitle>{props.t("Time Map")}</CardTitle>
                        <CardTitle className="mb-4">
                          {props.t("Total Number of Hours")}
                        </CardTitle>
                        {daycareStemp.feeDisplayDetails && (
                          <Progress
                            striped
                            color="primary"
                            style={{ height: "24px" }}
                            value={daycareStemp.feeDisplayDetails.totalTime}
                            className="mb-4"
                          >
                            {daycareStemp.feeDisplayDetails.totalTime}Hr{" "}
                            {daycareStemp.feeDisplayDetails.totalMin}Min
                          </Progress>
                        )}
                        <CardTitle className="mb-4">
                          {props.t("Extra Hours")}
                        </CardTitle>
                        {daycareStemp.feeDisplayDetails && (
                          <Progress
                            striped
                            color="danger"
                            style={{ height: "24px" }}
                            value={daycareStemp.feeDetail.extraTime}
                          >
                            {daycareStemp.feeDetail.extraTime}Hr{" "}
                            {daycareStemp.feeDetail.extraMin}Min
                          </Progress>
                        )}
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </TabPane>
          <TabPane tabId="6" className="p-3">
            <FeeTab
              kid={props.kid}
              schoolId={props.schoolId}
              currency={props.currency}
              showRoyalty={props.showRoyalty}
              completeDisable={props.completeDisable}
            />
          </TabPane>
          <TabPane tabId="7" className="p-3">
            <FormTab selectedKid={props.kid} schoolId={props.schoolId} />
          </TabPane>
          <TabPane tabId="9" className="p-3">
            <Row>
              <Col lg='3'></Col>
              <Col lg='6'>
                <Nav pills className="navtab-bg nav-justified d-print-none">
                  <NavItem className={activeCTGTab == '1' ? 'bg-primary' : 'bg-light'}>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeCTGTab === "1",
                      })}
                      onClick={() => {
                        setactiveCTGTab("1");
                      }}
                    >
                      <i className="bx bx-cctv "></i> {props.t("CCTV")}
                    </NavLink>
                  </NavItem>

                  <NavItem className={activeCTGTab == '2' ? 'bg-primary' : 'bg-light'}>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeCTGTab === "2",
                      })}
                      onClick={() => {
                        setactiveCTGTab("2");
                      }}
                    >
                      <i className="bx bx-group"></i> {props.t("Groups")}
                    </NavLink>
                  </NavItem>

                  <NavItem className={activeCTGTab == '3' ? 'bg-primary' : 'bg-light'}>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeCTGTab === "3",
                      })}
                      onClick={() => {
                        setactiveCTGTab("3");
                      }}
                    >
                      <i className="bx bx-bus"></i> {props.t("Trips")}
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
              <Col lg='3'></Col>
            </Row>
            <TabContent activeTab={activeCTGTab}>
              <TabPane tabId="1" className="p-3">
                <CCTVComponent
                  selectedKid={props.kid}
                  schoolId={props.schoolId}
                ></CCTVComponent>
              </TabPane>
              <TabPane tabId="2" className="p-3">
                <GroupDetails
                  selectedKid={props.kid}
                  schoolId={props.schoolId}
                />
              </TabPane>
              <TabPane tabId="3" className="p-3">
                <TripDetails
                  selectedKid={props.kid}
                  schoolId={props.schoolId}
                />
              </TabPane>
            </TabContent>
          </TabPane>
        </TabContent>
      </CardBody>
      <AddEscortModal
        addEscortWithDetails={() => { }}
        escortObject={selectedEscortObject}
        kidId={props.kid.id}
        isOpen={showAddEscortModel}
        closeModal={(pickNDropObj) => {
          setShowAddEscortModel(false);
          if (pickNDropObj && pickNDropObj.className === "Parent") {
            pickNDropObj.pin();

            props.updateParentObjectFromChild(pickNDropObj);
          } else if (pickNDropObj) {
            props.setPickAndDropDetailForKid([pickNDropObj]);
          }
        }}
      ></AddEscortModal>
      <Modal isOpen={isDeleteEscortModalOpen}>
        <ModalHeader
          toggle={() => {
            setisDeleteEscortModalOpen(undefined);
          }}
        >
          {"Alert"}
        </ModalHeader>
        <ModalBody>
          <div className="text-center">
            <h2>
              <i className="fas fa-exclamation-triangle text-danger mr-2"></i>
            </h2>
            <h5>Are you sure to delete this escort?</h5>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-primary"
            disabled={isDeletingEscort}
            onClick={() => {
              setisDeletingEscort(true);
              handleDeleteEscort(isDeleteEscortModalOpen.parseObject);
            }}
          >
            {isDeletingEscort && (
              <i className="bx bx-loader bx-spin mr-2 align-middle"></i>
            )}
            {isDeletingEscort ? "Deleting..." : "Delete"}
          </button>
        </ModalFooter>
      </Modal>
    </Card>
  );
};

const mapStatetoProps = (state) => {
  const { kidMedicalObj,pickAndDropObj,vaccinationDetails,attendanceDetails,daycareAttendanceDetails } = state.Kid;
  const { selectedSchool } = state.School;
  
  return {
    kidMedicalObj,
    pickAndDropObj,
    vaccinationDetails,
    attendanceDetails,
    daycareAttendanceDetails,
    selectedSchool,
  };
};
export default connect(mapStatetoProps, {
  getmedicalDetailsForAKid,
  getPickAndDropDetailForKid,
  setPickAndDropDetailForKid,
  getVaccinationList,
  getAttendanceForAKid,
  getAttendanceForKidDaycare,
  updateAdditionalDetailsForAKid,
  saveMedicalDetailsForAKid,
})(withRouter(withNamespaces()(OtherDetails)));
