import React, { useState, useEffect } from 'react';
import {
    Row, Col, Button, CardImg, Progress,
    Modal, Container, Label, ModalBody, UncontrolledTooltip, ModalHeader, Table, ModalFooter
} from "reactstrap";
import { withNamespaces } from 'react-i18next';
import { matchPath } from "react-router";
import * as WhiteLabel from "../../components/Common/WhiteLable";
import Slider from "react-rangeslider";

// actions
import {
    getAKid,
    setSelecetdSchool,
    getClassroomsForSchoolId,
    getAllKids,
    getParentsForFamilyIds,
    getmedicalDetailsForKidIds,
} from "../../store/actions";
import { Offsymbol, OnSymbol } from '../../components/Common/CommonFunctions';
import Switch from "react-switch";
// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
//Import Images
import StudentIdCardFrontView from './StudentIdCardFrontView';
import StudentQrCodeView from './StudentQrCodeView';
import StudentIdCardBackView from './StudentIdCardBackView';
import UpdatePhoto from '../../components/Common/UpdatePhoto';

const StudentIdCard = (props) => {


    const match = matchPath(props.history.location.pathname, {
        path: "/studentIdcard/:schoolId/:id",
        exact: true,
        strict: false,
    });

    const matchSchool = matchPath(props.history.location.pathname, {
        path: "/studentIdcard/:schoolId",
        exact: true,
        strict: false,
    });

    const forSchool = matchSchool ? true : false;
    const kidId = match && match.params.id;
    const schoolId = match ? match.params.schoolId : (matchSchool ? matchSchool.params.schoolId : undefined);
    const [isBorderOff, setIsBorderOff] = useState(false);
    const portraitSize = {
        id: 0,

        schoolLogoHeight: "5mm",
        topHeaderDivHeight: "15.5mm",
        bottomFooterDivHeight: "15.1mm",
        kidDetailHeight: "57mm",
        kidPhotoHeight: "17mm",
        colForKidDetails: 12,
        rowForKidDetails: 12,
        qrCodeDivHeight: "18mm",

        cardHeight: "86mm",
        cardWidth: "54mm",
        nameStyle: { fontSize: "x-small", maxHeight: "5mm", overflow: "hidden" },
        marginTopForKidName: "",
        kidPhotoMarginTop: ""
    }

    const landscapeSize = {
        id: 1,

        schoolLogoHeight: "12mm",
        topHeaderDivHeight: "14mm",
        topTitleDivHeight: "5mm",
        bottomFooterDivHeight: "12mm",
        kidDetailHeight: "22mm",
        kidPhotoHeight: "21mm",
        colForKidDetails: 5,
        rowForKidDetails: 12,
        qrCodeDivHeight: "16mm",

        cardHeight: "54mm",
        cardWidth: "86mm",
        nameStyle: { fontSize: "x-small", minHeight: "5mm", maxHeight: "5mm", overflow: "hidden"},
        marginTopForKidName: "",
        kidPhotoMarginTop: "",
        footerW1:"28mm",
        footerW2:"57mm"
    }
    
    const withoutQRCodePortrait = {
        id: 2,

        schoolLogoHeight: "5mm",
        topHeaderDivHeight: "15.5mm",
        bottomFooterDivHeight: "15.1mm",
        kidDetailHeight: "55mm",
        kidPhotoHeight: "27mm",
        colForKidDetails: 12,
        rowForKidDetails: 12,
        qrCodeDivHeight: "0mm",
        
        cardHeight: "86mm",
        cardWidth: "54mm",
        nameStyle: { fontSize: "x-small", maxHeight: "8mm", overflow: "hidden" },
        marginTopForKidName: "mt-3",
        kidPhotoMarginTop: "mt-2"
    }

    const withoutQRCodeLandscape = {
        id: 3,

        schoolLogoHeight: "12mm",
        topHeaderDivHeight: "14mm",
        topTitleDivHeight: "5mm",
        bottomFooterDivHeight: "12mm",
        kidDetailHeight: "22mm",
        kidPhotoHeight: "21mm",
        colForKidDetails: 4,
        rowForKidDetails: 12,
        noQRLogoDivHeight: "16mm",
        
        cardHeight: "54mm",
        cardWidth: "86mm",
        nameStyle: { fontSize: "x-small", minHeight: "5mm", maxHeight: "5mm", overflow: "hidden" },
        marginTopForKidName: "",
        kidPhotoMarginTop: "",
        footerW1:"28mm",
        footerW2:"57mm"
    }

    const onlyQRCode = {
        id: 4,

        schoolLogoHeight: "5mm",
        topHeaderDivHeight: "20.3090275mm",
        bottomFooterDivHeight: "20.3090275mm",
        kidDetailHeight: "42.3090275mm",
        kidPhotoHeight: "25mm",
        colForKidDetails: 6,
        rowForKidDetails: 12,
        qrCodeDivHeight: "60.3090275mm",
        
        cardHeight: "125.23611mm",
        cardWidth: "78.636627209302mm",
        nameStyle: { fontSize: "x-small" },
        marginTopForKidName: "",
        kidPhotoMarginTop: ""
    }

    const HeaderSettingTypes = {
        logoAndName: "logoAndName",
        logoOnly: "logoOnly",
        customLogo: "customLogo",
    };

    const frontView = "frontView"
    const backView = "backView"
    const fNBView = "fNBView"

    const updateSizeScale = (scale) => {
        let ratio = 1 + scale;
        if (orientaion=="P" && LayoutType=="QR" ) {
            let os = {

                id: 0,

                schoolLogoHeight: 5 * ratio + "mm",
                topHeaderDivHeight: 15.5 * ratio + "mm",
                bottomFooterDivHeight: 15.1 * ratio + "mm",
                kidDetailHeight: 57 * ratio + "mm",
                kidPhotoHeight: 17 * ratio + "mm",
                colForKidDetails: 12 * ratio,
                rowForKidDetails: 12 * ratio,
                qrCodeDivHeight: 18 * ratio + "mm",

                cardHeight: 86 * ratio + "mm",
                cardWidth: 54 * ratio + "mm",
                nameStyle: { fontSize: "x-small", maxHeight: 5 * ratio + "mm", overflow: "hidden" },
                marginTopForKidName: "",
                kidPhotoMarginTop: ""

            }
            setSelectedPrintSetting(os)

        } else if (orientaion=="P" && LayoutType=="NoQR") {
            let oq = {
                id: 2,

                schoolLogoHeight: 5 * ratio + "mm",
                topHeaderDivHeight: 15.5 * ratio + "mm",
                bottomFooterDivHeight: 15.1 * ratio + "mm",
                kidDetailHeight: 55 * ratio + "mm",
                kidPhotoHeight: 27 * ratio + "mm",
                colForKidDetails: 12 * ratio,
                rowForKidDetails: 12 * ratio,
                qrCodeDivHeight: "0mm",



                cardHeight: 86 * ratio + "mm",
                cardWidth: 54 * ratio + "mm",
                nameStyle: { fontSize: "x-small", maxHeight: 8 * ratio + "mm", overflow: "hidden" },
                marginTopForKidName: "mt-3",
                kidPhotoMarginTop: "mt-2"
            }

            setSelectedPrintSetting(oq)

        }else if (orientaion=="L" && LayoutType=="QR" ) {
            let os = {

                id: 1,

                schoolLogoHeight: 12 * ratio + "mm",
                topHeaderDivHeight: 14 * ratio + "mm",
                topTitleDivHeight: 5 * ratio + "mm",
                bottomFooterDivHeight: 12 * ratio + "mm",
                kidDetailHeight: 22 * ratio + "mm",
                kidPhotoHeight: 21 * ratio + "mm",
                colForKidDetails: 5 * ratio,
                rowForKidDetails: 12 * ratio,
                qrCodeDivHeight: 16 * ratio + "mm",

                cardHeight: 54 * ratio + "mm",
                cardWidth: 86 * ratio + "mm",
                nameStyle: { fontSize: "x-small", minHeight: 5 * ratio + "mm", maxHeight: 5 * ratio + "mm", overflow: "hidden" },
                marginTopForKidName: "",
                kidPhotoMarginTop: "",
                footerW1: 28 * ratio + "mm",
                footerW2: 57 * ratio + "mm",

            }
            setSelectedPrintSetting(os)

        } else if (orientaion=="L" && LayoutType=="NoQR") {
            let oq = {
                id: 3,

                schoolLogoHeight: 12 * ratio + "mm",
                topHeaderDivHeight: 14 * ratio + "mm",
                topTitleDivHeight: 5 * ratio + "mm",
                bottomFooterDivHeight: 12 * ratio + "mm",
                kidDetailHeight: 22 * ratio + "mm",
                kidPhotoHeight: 21 * ratio + "mm",
                noQRLogoDivHeight: 16 * ratio+"mm",
                
                cardHeight: 54 * ratio + "mm",
                cardWidth: 86 * ratio + "mm",
                nameStyle: { fontSize: "x-small", minHeight: 5 * ratio + "mm", maxHeight: 5 * ratio + "mm", overflow: "hidden" },
                marginTopForKidName: "",
                kidPhotoMarginTop: "",
                footerW1: 28 * ratio + "mm",
                footerW2: 57 * ratio + "mm",

            }
            setSelectedPrintSetting(oq)
        }

    }

    Object.freeze(HeaderSettingTypes);

    const [selectedPrintSetting, setSelectedPrintSetting] = useState(portraitSize)
    const [sliderValue, setSliderValue] = useState(0)

    const [selectedViewSetting, setSelectedViewSetting] = useState(frontView)
    const [showSettingsModal, setShowSettingsModal] = useState(false);
    const [allStudentList, setAllStudentsList] = useState([])
    const [allStudentListMaster, setAllStudentsListMaster] = useState([])
    const [allStudentListIds, setAllStudentsListIds] = useState([])
    const [allStudentMasterListIds, setAllStudentsMasterListIds] = useState([])
    
    const [orientaion,setOrientaion ]=useState("P");
    const [LayoutType,setLayoutType ]=useState("QR");
    const [onlyQR,setOnlyQR ]=useState(false)


    const [headerFontSize, setHeaderFontSize] = useState(14);
    const [bodyFontSize, setBodyFontSize] = useState(11);
    const [footerFontSize, setFooterFontSize] = useState(10);
    const [selectedHeaderTypeSetting, setSelectedHeaderTypeSetting] = useState(HeaderSettingTypes.logoAndName);
    const [showLogoUploadView, setShowLogoUploadView] = useState(false);
    const [idCardLogo, setIdCardLogo] = useState();
    const [idCardLogoUploadProgress, setIdCardLogoUploadProgress] = useState(0);
    const [showPopup, setShowPopup] = useState(false);

    const getClassroomName = (ClassRoomID) => {
        if (props.classrooms && props.classrooms.length > 0) {
            let temp = props.classrooms.filter(
                (classroom) => classroom.id === ClassRoomID
            );
            if (temp.length > 0) {
                return (temp[0].attributes.Name ?? "-")
            }
        }
    }

    useEffect(() => {
        props.getClassroomsForSchoolId(schoolId);
        if (forSchool) {
            props.getAllKids(schoolId)
        }
    }, [schoolId]);

    const changeCustomHeaderLogo = (result) => {
        if (result) {
            let name = result._name.split(/_(.+)/)[1];
            let fileExtension = name.split(".");
            let tempFile = {
                actualImageName: name,
                fileExtension: fileExtension[1].toLowerCase(),
                imageName: fileExtension[0],
                image: result,
                imageURL: result._url,
            };
            setIdCardLogoUploadProgress(100);
            setIdCardLogo(tempFile)

        }
        setHeaderFontSize(0)
        setSelectedHeaderTypeSetting(HeaderSettingTypes.customLogo)
        setShowLogoUploadView(true)

    }

    const changeToDefaultHeaderType = () => {
        setIdCardLogo()
        setHeaderFontSize(14)
        setShowLogoUploadView(false)
        setSelectedHeaderTypeSetting(HeaderSettingTypes.logoAndName)
    }

    useEffect(() => {
        if (props.selectedSchool && props.selectedSchool.attributes.idCardLogo) {
            changeCustomHeaderLogo(props.selectedSchool.attributes.idCardLogo)
        } else {
            if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
                props.setSelecetdSchool(schoolId);
            }
            changeToDefaultHeaderType()
        }
    }, [props.selectedSchool, schoolId])

    // console.log(props.classrooms)
    // console.log(props.kids)

    useEffect(() => {
        if (kidId) {
            props.getAKid(kidId);
        }
    }, [kidId]);

    useEffect(() => {
        if (props.kids) {
            var tempList = [...props.kids];
            setAllStudentsList(tempList);
            updateLists(tempList);
            let familyIds = [];
            for (var j = 0; j < props.kids.length; j++) {
                familyIds.push(props.kids[j].attributes.FamilyID);
            }
            if (familyIds.length > 0) {
                props.getParentsForFamilyIds(familyIds);
            }
        }
    }, [props.kids]);

    useEffect(() => {
        if (props.kids) {
            let kidIds = [];
            for (var j = 0; j < props.kids.length; j++) {
                kidIds.push(props.kids[j].id);
            }
            if (kidIds.length > 0) {
                props.getmedicalDetailsForKidIds(kidIds);
            }
        }
    }, [props.kids])

    function updateLists(tempList) {
        let ids = [];
        if (props.kids) {
            for (const i in tempList) {
                ids.push(tempList[i].id)
            }
        }
        setAllStudentsList(tempList);
        setAllStudentsListMaster(tempList);
        setAllStudentsListIds(ids);
        setAllStudentsMasterListIds(ids)
    }

    function printPoster() {
        var css = "@page { size: A3 ;margin:0; }",
            head = document.head || document.getElementsByTagName("head")[0],
            style = document.createElement("style");

        style.type = "text/css";
        style.media = "print";

        if (style.styleSheet) {
            style.styleSheet.cssText = css;
        } else {
            style.appendChild(document.createTextNode(css));
        }

        head.appendChild(style);
        window.print();
    }


    const getUIBasedOnViewSettings = (kid) => {
        if (selectedViewSetting === frontView) {
            return (

                <div style={{ pageBreakInside: 'avoid' }}>
                    <StudentIdCardFrontView
                        idCardLogo={idCardLogo}
                        bodyFontSize={bodyFontSize}
                        footerFontSize={footerFontSize}
                        headerFontSize={headerFontSize}
                        school={props.selectedSchool}
                        kid={kid}
                        classrooms={props.classrooms}
                        kidMedicalObjects={props.kidMedicalObjects}
                        parents={props.parents ?? []}
                        isBorderOff={isBorderOff}
                        orientaion={orientaion}
                        LayoutType={LayoutType}
                        selectedPrintSetting={selectedPrintSetting}
                        selectedHeaderTypeSetting={selectedHeaderTypeSetting}
                        onlyQR={onlyQR}
                    />
                </div>)
        } else if (selectedViewSetting === backView) {
            return (
                <div style={{ pageBreakInside: 'avoid' }}>
                    <StudentIdCardBackView
                        bodyFontSize={bodyFontSize}
                        idCardLogo={idCardLogo}
                        footerFontSize={footerFontSize}
                        headerFontSize={headerFontSize}
                        school={props.selectedSchool}
                        kid={kid}
                        classrooms={props.classrooms}
                        parents={props.parents ?? []}
                        isBorderOff={isBorderOff}
                        orientaion={orientaion}
                        LayoutType={LayoutType}
                        selectedPrintSetting={selectedPrintSetting}
                    /></div>)
        } else if (selectedViewSetting === fNBView) {
            return (
                <div style={{ pageBreakInside: 'avoid' }}>
                    <StudentIdCardFrontView
                        bodyFontSize={bodyFontSize}
                        idCardLogo={idCardLogo}
                        headerFontSize={headerFontSize}
                        footerFontSize={footerFontSize}
                        school={props.selectedSchool}
                        kid={kid}
                        classrooms={props.classrooms}
                        parents={props.parents ?? []}
                        isBorderOff={isBorderOff}
                        orientaion={orientaion}
                        LayoutType={LayoutType}
                        selectedPrintSetting={selectedPrintSetting}
                        selectedHeaderTypeSetting={selectedHeaderTypeSetting}
                        onlyQR={onlyQR}
                    />
                    <StudentIdCardBackView
                        bodyFontSize={bodyFontSize}
                        idCardLogo={idCardLogo}
                        headerFontSize={headerFontSize}
                        footerFontSize={footerFontSize}
                        className="float-right"
                        school={props.selectedSchool}
                        kid={kid}
                        classrooms={props.classrooms}
                        parents={props.parents ?? []}
                        isBorderOff={isBorderOff}
                        orientaion={orientaion}
                        LayoutType={LayoutType}
                        selectedPrintSetting={selectedPrintSetting}
                        selectedHeaderTypeSetting={selectedHeaderTypeSetting}
                    />
                </div>
            )
        }
    }
    // console.log('Orientation:', orientaion);
    // console.log('LayoutType:', LayoutType);
    // console.log('Selected Print Setting:', selectedPrintSetting);
    // console.log('withoutQRCodeLandscape:', withoutQRCodeLandscape);
    // console.log('landscapeSize:', landscapeSize);

    const getSingleCardUI = (isPrintOnly) => {

        let classNameTemp = isPrintOnly ? "d-none d-print-block ml-0 pl-0 mr-0 pr-0" : "d-print-none ml-0 pl-0 mr-0 pr-0"
        let styleTemp = { width: "100%", minHeight: "1420px" }
        if (isPrintOnly) {
            styleTemp.backgroundColor = "white"
        }

        return (
            <Col lg="12" md="12" sm="12" xs="12" className={classNameTemp}>
                <center>
                    <div
                        style={styleTemp}
                        className="text-center"
                    >
                        {onlyQR ?
                            <div className='d-flex justify-content-center' style={{ pageBreakInside: 'avoid' }}>
                                <StudentQrCodeView
                                    school={props.selectedSchool}
                                    classrooms={props.classrooms}
                                    kid={props.selectedKid}
                                    parents={props.parents ?? []}

                                    selectedPrintSetting={onlyQRCode}
                                ></StudentQrCodeView>
                            </div> :
                            <div className='d-flex justify-content-center'>
                                {getUIBasedOnViewSettings(props.selectedKid)}
                            </div>
                        }
                    </div>
                </center>
            </Col>
        )
    }

    const getMultiCardUI = () => {
        let styleTemp = { width: "100%", minHeight: "1420px" }
        styleTemp.backgroundColor = "white"
        return (
            <div className="mx-auto">
                <div style={styleTemp} className="d-flex justify-content-center">
                    <div className='ml-4 pl-3'>
                        <Row>
                            {allStudentList.map((stud, key) => (
                                <Col className="mb-4" key={key}>
                                    <React.Fragment>
                                        {onlyQR ? (
                                            <div className='d-flex justify-content-center' style={{ pageBreakInside: 'avoid' }}>
                                                <StudentQrCodeView
                                                    school={props.selectedSchool}
                                                    classrooms={props.classrooms}
                                                    kid={stud}
                                                    parents={props.parents ?? []}
                                                    selectedPrintSetting={onlyQRCode}
                                                />
                                            </div>
                                        ) : (
                                            <div className='d-flex justify-content-center'>
                                                {getUIBasedOnViewSettings(stud)}
                                            </div>
                                        )}
                                    </React.Fragment>
                                </Col>
                            ))}
                        </Row>
                    </div>
                </div>
            </div>

        )
    }

    const getParentUIWithHeader = (isSingleCardUI) => {
        return (


            <Row className="text-center bg-white">
                {
                    <>
                        <Col xs="12" className="d-print-none mt-3 mb-3">
                            <div className="navbar-header p-0" style={{ borderBottom: "1px solid gray" }}>
                                {/* <div
                                        style={{ cursor: "pointer" }}
                                        onClick={() => { window.history.back() }}>
                                        <i className="bx bx-chevron-left h1 ml-3 text-primary"></i>
                                    </div> */}

                                <h4 className="text-center m-4"><img src={WhiteLabel.getAppLogoWithName()} className="m-5" height="50px" style={{ border: "1px solid lightGray", borderRadius: "10px", padding: "10px" }}></img>{"STUDENT ID CARD"}</h4>
                                <div className="d-flex m-4">
                                    <Row className="d-flex  m-4">
                                        <div>
                                            {!kidId &&
                                                <Button
                                                    color="primary"
                                                    className='float-right btn btn-primary waves-effect waves-light m-1'
                                                    onClick={() => {
                                                        setShowPopup(true)
                                                    }
                                                    }
                                                >
                                                    Add/Remove
                                                </Button>
                                            }
                                            <Button
                                                color="primary"
                                                className='float-right btn btn-primary waves-effect waves-light m-1'
                                                onClick={() => {
                                                    setShowSettingsModal(true)
                                                }
                                                }
                                            >
                                                Customise
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    printPoster();
                                                }}
                                                className="btn btn-success waves-effect waves-light m-1"
                                            >
                                                <i className="fa fa-print m-1"></i>Print
                                            </Button>
                                        </div>
                                    </Row>
                                </div>
                            </div>
                        </Col>

                        {isSingleCardUI && <>
                            <Row className="d-print-none d-flex justify-content-center m-3">{getSingleCardUI()}</Row>
                            {getSingleCardUI(true)}
                        </>}
                        {!isSingleCardUI && <>
                            {getMultiCardUI()}
                        </>}
                    </>}
            </Row>
        )
    }


    const getLoadingUI = () => {
        return (
            <Col xs="12">
                <div className="text-center my-3">
                    <Link to="#" className="text-success" onClick={(e) => {
                        e.preventDefault()
                    }}>
                        <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                        {forSchool ? "Loading Student Id Cards..." : "Loading Student Id Card..."}  {" "}
                    </Link>
                </div>
            </Col>
        )
    }



    const changeHeaderFontSize = (isLeft) => {
        if (isLeft) {
            if (headerFontSize != 0) {
                setHeaderFontSize(headerFontSize - 1)
            }
        } else {
            setHeaderFontSize(headerFontSize + 1)
        }
    }

    const changeFooterFontSize = (isLeft) => {
        if (isLeft) {
            if (footerFontSize != 0) {
                setFooterFontSize(footerFontSize - 1)
            }
        } else {
            setFooterFontSize(footerFontSize + 1)
        }
    }

    const changeBodyFontSize = (isLeft) => {
        if (isLeft) {
            if (bodyFontSize != 0) {
                setBodyFontSize(bodyFontSize - 1)
            }
        } else {
            setBodyFontSize(bodyFontSize + 1)
        }
    }

    // On image upload
    function handleImageUploadChange(
        changedImageURL,
        imageFile,

    ) {
        setIdCardLogo(undefined)
        setIdCardLogoUploadProgress(1)
        let fileExtension = imageFile._name.split(".");
        let tempFile = {
            actualImageName: imageFile._name,
            fileExtension: fileExtension[1].toLowerCase(),
            imageName: fileExtension[0],
            image: imageFile,
            imageURL: undefined,
        };
        tempFile.image
            .save({
                progress: (value) => {
                    if (value && value > 0.1) {
                        //setUploadProgress(value * 100);
                        //setProgressText(props.t('Uploading....')+": "+(value*100).toFixed(2)+"%")
                        setIdCardLogoUploadProgress(value * 100)
                    }
                },
            })
            .then(
                (result) => {
                    if (props.selectedSchool) {
                        props.selectedSchool.set("idCardLogo", result)
                        props.selectedSchool.save().then(
                            (schoolResult) => {
                                tempFile.imageURL = result._url;
                                tempFile.image = result;
                                setIdCardLogo(tempFile)
                            },
                            (error) => {

                            }
                        );
                    }
                },
                (error) => {
                }
            );
        setIdCardLogo({ actualImageName: tempFile.actualImageName, imageURL: changedImageURL })
    }


    return (

        <React.Fragment >
            <Modal isOpen={showSettingsModal} toggle={() => { setShowSettingsModal(!showSettingsModal) }} scrollable={true}>
                <ModalHeader toggle={() => {
                    setShowSettingsModal(!showSettingsModal)
                }}>
                    Customise ID Card
                </ModalHeader>
                <ModalBody>
                    <div className='mt-2'>
                        <Row>
                            <div className="d-flex justify-content-start">
                                <button
                                    onClick={() => {
                                        setOrientaion("P")
                                        if(onlyQR){
                                            setOnlyQR(false)
                                        }
                                        if(LayoutType=="NoQR"){
                                            setSelectedPrintSetting(withoutQRCodePortrait)
                                        }else{
                                            setSelectedPrintSetting(portraitSize)
                                        }

                                    }}
                                    className={
                                        ( orientaion=="P")
                                            ? "btn btn-success btn-sm waves-effect waves-light m-2"
                                            : "btn btn-secondary btn-sm waves-effect waves-light m-2"
                                    }
                                >
                                    Portrait
                                </button>
                                <button
                                    onClick={() => {
                                        setOrientaion("L")
                                        if(onlyQR){
                                            setOnlyQR(false)
                                        }
                                        if(LayoutType=="NoQR"){
                                            setSelectedPrintSetting(withoutQRCodeLandscape)
                                        }else{
                                            setSelectedPrintSetting(landscapeSize)
                                        }
                                    }}
                                    className={
                                        (orientaion == "L")
                                            ? "btn btn-success btn-sm waves-effect waves-light m-2"
                                            : "btn btn-secondary btn-sm waves-effect waves-light m-2"
                                    }
                                >
                                    Landscape
                                </button>


                                <button
                                    onClick={() => {
                                        setLayoutType("QR")
                                        if(onlyQR){
                                            setOnlyQR(false)
                                        }

                                        if(orientaion=="P"){
                                            setSelectedPrintSetting(portraitSize)
                                        }else{
                                            setSelectedPrintSetting(landscapeSize)
                                        }
                                    }}
                                    className={
                                        LayoutType === "QR"
                                            ? "btn btn-success btn-sm waves-effect waves-light m-2"
                                            : "btn btn-secondary btn-sm waves-effect waves-light m-2"
                                    }
                                >
                                    With QR
                                </button>
                                <button
                                    onClick={() => {
                                        setLayoutType("NoQR")
                                        if(onlyQR){
                                            setOnlyQR(false)
                                        }
                                        if(orientaion=="P"){
                                            setSelectedPrintSetting(withoutQRCodePortrait)
                                        }else{
                                            setSelectedPrintSetting(withoutQRCodeLandscape)
                                        }

                                    }}
                                    className={
                                        LayoutType === "NoQR"
                                            ? "btn btn-success btn-sm waves-effect waves-light m-2"
                                            : "btn btn-secondary btn-sm waves-effect waves-light m-2"
                                    }
                                >
                                    Without QR
                                </button>
                            </div>
                        </Row>
                        
                        <Row>
                            <div className="d-flex justify-content-start">
                                <button onClick={() => {
                                    if (selectedViewSetting !== frontView) {
                                        setSelectedViewSetting(frontView)
                                    }
                                }} className={selectedViewSetting === frontView ? "btn btn-success btn-sm waves-effect waves-light m-2" : "btn btn-secondary btn-sm waves-effect waves-light m-2"}>
                                    Front
                                </button>
                                {!onlyQR&& <button onClick={() => {
                                    if (selectedViewSetting !== backView) {
                                        setSelectedViewSetting(backView)
                                    }
                                }} className={selectedViewSetting === backView ? "btn btn-success btn-sm waves-effect waves-light m-2" : "btn btn-secondary btn-sm waves-effect waves-light m-2"}>
                                    Back
                                </button>}
                                {!onlyQR&& <button onClick={() => {
                                    if (selectedViewSetting !== fNBView) {
                                        setSelectedViewSetting(fNBView)
                                    }
                                }} className={selectedViewSetting === fNBView ? "btn btn-success btn-sm waves-effect waves-light m-2" : "btn btn-secondary btn-sm waves-effect waves-light m-2"}>
                                    {"Front & Back"}
                                </button>}
                            </div>
                        </Row>

                        <Row>
                            <Col className="d-flex justify-content-start">
                                <Label className='m-2'>OR</Label>
                                <button
                                    onClick={() => {
                                        setOnlyQR(!onlyQR)
                                    }}
                                    className={
                                        onlyQR
                                            ? "btn btn-success btn-sm waves-effect waves-light m-2"
                                            : "btn btn-secondary btn-sm waves-effect waves-light m-2"
                                    }
                                >
                                    Only QR
                                </button>
                            </Col>
                        </Row>
                        <hr></hr>
                        <Row className='m-1'><Col><label>Header Settings</label></Col></Row>
                        <Row>
                            <div className="d-flex justify-content-start">
                                <button onClick={() => {
                                    setHeaderFontSize(14)
                                    setSelectedHeaderTypeSetting(HeaderSettingTypes.logoAndName)
                                    setShowLogoUploadView()
                                    setIdCardLogo()
                                    setIdCardLogoUploadProgress(0)
                                }} className={selectedHeaderTypeSetting === HeaderSettingTypes.logoAndName ? "btn btn-success btn-sm waves-effect waves-light m-2" : "btn btn-secondary btn-sm waves-effect waves-light m-2"}>{"Logo & Name"}</button>
                                <button onClick={() => {
                                    // setHeaderFontSize(0)
                                    setSelectedHeaderTypeSetting(HeaderSettingTypes.logoOnly)
                                    setShowLogoUploadView()
                                    setIdCardLogo()
                                    setIdCardLogoUploadProgress(0)

                                }} className={selectedHeaderTypeSetting === HeaderSettingTypes.logoOnly ? "btn btn-success btn-sm waves-effect waves-light m-2" : "btn btn-secondary btn-sm waves-effect waves-light m-2"}>{"Logo Only"}</button>
                                <button onClick={() => {
                                    changeCustomHeaderLogo(props.selectedSchool && props.selectedSchool.attributes.idCardLogo)

                                }} className={selectedHeaderTypeSetting === HeaderSettingTypes.customLogo ? "btn btn-success btn-sm waves-effect waves-light m-2" : "btn btn-secondary btn-sm waves-effect waves-light m-2"}>{"Custom Logo"}</button>
                            </div>
                        </Row>
                        {showLogoUploadView && <Row>
                            <Col>
                                {idCardLogo &&
                                    idCardLogoUploadProgress === 100 && (
                                        <Col xl="12" md="12" sm="12" xs="12">
                                            <div className="border p-3">
                                                <Row>
                                                    <div className="ml-3">
                                                        <img
                                                            style={{ width: "12rem", height: "4rem" }}
                                                            className="rounded avatar"
                                                            src={idCardLogo.imageURL}
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div
                                                        className="float-right m-2"
                                                        style={{ width: "200px" }}
                                                    >
                                                        <div className="text-muted font-weight-bold">
                                                            {idCardLogo.actualImageName}
                                                        </div>
                                                        <div className="d-flex mt-3">
                                                            <UpdatePhoto
                                                                isRectangle={true}
                                                                id={"idCardCustom"}
                                                                tempId={"idCardCustom"}
                                                                className="m-2"
                                                                buttonTitle="Change"
                                                                isPopupOn={false}
                                                                onPicChange={(imageFile, url) => {
                                                                    handleImageUploadChange(

                                                                        url,
                                                                        imageFile,
                                                                    );
                                                                }}
                                                                imageFileName={
                                                                    idCardLogo
                                                                        ? idCardLogo.actualImageName
                                                                        : ""
                                                                }
                                                                initialImage={
                                                                    idCardLogo.imageURL
                                                                }
                                                            ></UpdatePhoto>
                                                            <Button
                                                                style={{ marginLeft: "10px" }}
                                                                className="btn-danger float-right"
                                                                onClick={() => {
                                                                    setIdCardLogo(undefined)
                                                                    setIdCardLogoUploadProgress(0)
                                                                    if (props.selectedSchool) {
                                                                        props.selectedSchool.unset("idCardLogo")
                                                                        props.selectedSchool.save().then(
                                                                            (result) => {

                                                                            },
                                                                            (error) => {

                                                                            }
                                                                        );
                                                                    }
                                                                }}
                                                            >
                                                                Clear
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </Row>
                                            </div>
                                        </Col>
                                    )}
                                {idCardLogo &&
                                    idCardLogoUploadProgress >= 1 &&
                                    idCardLogoUploadProgress < 100 && (
                                        <Col xl="12" md="12" sm="12" xs="12">
                                            <div className="border p-3">
                                                <Row>
                                                    <div
                                                        className="avatar ml-3"
                                                        style={{ width: "5rem", height: "5rem" }}
                                                    >
                                                        <span
                                                            className={
                                                                "avatar-title rounded-circle bg-soft-primary text-primary"
                                                            }
                                                            style={{ fontSize: "60px" }}
                                                        ></span>
                                                    </div>
                                                    <div
                                                        className="float-right ml-4"
                                                        style={{ width: "200px" }}
                                                    >
                                                        <div className="text-muted font-weight-bold">
                                                            {idCardLogo.actualImageName}
                                                        </div>
                                                        <div className=" mt-3">
                                                            <Progress

                                                                color="primary"
                                                                value={idCardLogoUploadProgress}
                                                            ></Progress>
                                                        </div>
                                                    </div>
                                                </Row>
                                            </div>
                                        </Col>
                                    )}
                                {idCardLogo == undefined && (
                                    <div className="d-flex">
                                        <div>
                                            {" "}
                                            <UpdatePhoto
                                                isRectangle={true}
                                                buttonTitle={"Upload Custom Logo"}
                                                buttonTitleInitial={"Upload Custom Logo"}
                                                id={"idCardCustomImage"}
                                                tempId={"idCardCustomImage"}
                                                className="p-1"
                                                isPopupOn={false}
                                                onPicChange={(imageFile, url) => {
                                                    handleImageUploadChange(
                                                        url,
                                                        imageFile,
                                                    );
                                                }}
                                                initialImage={""}
                                            ></UpdatePhoto>
                                        </div>
                                    </div>
                                )}
                            </Col>
                        </Row>}
                        <hr></hr>

                        <Row className='d-flex justify-content-between'>
                            <Col>
                                <label className='mb-1 mt-2'>Card Border</label>
                            </Col>
                            <Col className='d-flex justify-content-end'>
                                <div>

                                    {<Link
                                        to="#"
                                        className="float-right"
                                        style={{ cursor: "pointer" }}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            setIsBorderOff(false)
                                            setHeaderFontSize(14)
                                            setBodyFontSize(11)
                                            setFooterFontSize(10)
                                        }}
                                        id="IdCardSettingsReloadButton"
                                    >
                                        <i style={{ fontSize: "30px" }} className="bx bx-refresh text-primary"></i>
                                        <UncontrolledTooltip
                                            placement="top"
                                            target={"IdCardSettingsReloadButton"}
                                        >
                                            {props.t("Reset")}
                                        </UncontrolledTooltip>
                                    </Link>}
                                </div>
                            </Col>

                        </Row>
                        <Row>
                            <div className='d-flex'>
                                <div className='m-2'>
                                    <label className='float-left'>Disable</label>
                                </div>
                                <Switch
                                    height={15}
                                    width={25}
                                    className="m-2"
                                    uncheckedIcon={<Offsymbol />}
                                    checkedIcon={<OnSymbol />}
                                    onColor={"#6add7d"}
                                    onChange={() => {
                                        setIsBorderOff(!isBorderOff)
                                    }}
                                    checked={!isBorderOff}
                                />
                                <div className='m-2'>
                                    <label className='float-left'>Enable</label>
                                </div>
                            </div>
                        </Row>
                        <Row>
                            <Col>
                                <div className='d-flex mt-2'>
                                    <label className='float-left mt-3' style={{ minWidth: "30mm", maxWidth: "30mm" }}>Header font size</label>
                                    <div className='float-right d-flex ml-3 pt-2 mt-1 pl-2 pr-2' style={{ minWidth: "25mm", maxWidth: "25mm", border: "2px solid lightGrey", borderRadius: "6px", marginLeft: '30px' }}>
                                        <div className='m-2' onClick={() => changeHeaderFontSize(true)}>
                                            <i className='bx bxs-left-arrow font-size-16 align-middle'></i>
                                        </div>
                                        <div className='d-flex align-items-center m-2 font-size-16' style={{ minWidth: "3mm", maxWidth: "3mm", marginBottom: '8px' }}>{headerFontSize}</div>
                                        {headerFontSize < 20 && (
                                            <div className='m-2' onClick={() => changeHeaderFontSize()}>
                                                <i className='bx bxs-right-arrow font-size-16 align-middle'></i>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </Col>
                        </Row>


                        <Row>
                            <Col>
                                <div className='d-flex mt-2'>
                                    <label className='float-left mt-3' style={{ minWidth: "30mm", maxWidth: "30mm" }}>Body font size </label>
                                    <div className='float-right d-flex ml-3 pt-2 mt-1 pl-2 pr-2' style={{ minWidth: "25mm", maxWidth: "25mm", border: "2px solid lightGrey", borderRadius: "6px", marginLeft: '30px' }}>
                                        <div className='m-2' onClick={() => changeBodyFontSize(true)}>
                                            <i className='bx bxs-left-arrow font-size-16 align-middle'></i>
                                        </div>
                                        <div className='d-flex align-items-center m-2 font-size-16' style={{ minWidth: "3mm", maxWidth: "3mm", marginBottom: '8px' }}>{bodyFontSize}</div>
                                        {bodyFontSize < 20 && (
                                            <div className='m-2' onClick={() => changeBodyFontSize()}>
                                                <i className='bx bxs-right-arrow font-size-16 align-middle'></i>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <div className='d-flex mt-2'>
                                    <label className='float-left mt-3' style={{ minWidth: "30mm", maxWidth: "30mm" }}>Footer font size </label>
                                    <div className='float-right d-flex ml-3 pt-2 mt-1 pl-2 pr-2' style={{ minWidth: "25mm", maxWidth: "25mm", border: "2px solid lightGrey", borderRadius: "6px", marginLeft: '30px' }}>
                                        <div className='m-2' onClick={() => changeFooterFontSize(true)}>
                                            <i className='bx bxs-left-arrow font-size-16 align-middle'></i>
                                        </div>
                                        <div className='d-flex align-items-center m-2 font-size-16' style={{ minWidth: "3mm", maxWidth: "3mm", marginBottom: '8px' }}>{footerFontSize}</div>
                                        {footerFontSize < 20 && (
                                            <div className='m-2' onClick={() => changeFooterFontSize()}>
                                                <i className='bx bxs-right-arrow font-size-16 align-middle'></i>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="p-3">
                                    <Label>Card Size</Label>
                                    <Slider
                                        value={sliderValue}
                                        orientation="horizontal"
                                        min={0}
                                        max={1}
                                        step={0.1}
                                        onChange={value => {
                                            updateSizeScale(value);
                                            setSliderValue(value);
                                        }}
                                    />
                                </div>

                            </Col>
                        </Row>

                        <div>
                            <div className='d-flex justify-content-end'>
                                <button className='btn btn-primary' onClick={() => {
                                    setShowSettingsModal(!showSettingsModal)
                                }}>Done</button>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
            {
                forSchool ?
                    (props.selectedSchool && props.selectedSchool.id === schoolId &&
                        allStudentList && allStudentList.length > 0 ? getParentUIWithHeader() : getLoadingUI())
                    :
                    (props.selectedSchool && props.selectedSchool.id === schoolId &&
                        props.selectedKid && props.selectedKid.id === kidId && props.classrooms && props.classrooms.length > 0 ?
                        getParentUIWithHeader(true) : getLoadingUI())
            }
            <Modal isOpen={showPopup} toggle={() => { setShowPopup(!showPopup) }} scrollable={true}>
                <ModalHeader toggle={() => {
                    setShowPopup(!showPopup)
                }}>
                    Add/Remove <br></br>
                    <small>Please select/unselect the profile which you want to print</small>
                </ModalHeader>

                <ModalBody>
                    <div className='mt-2'>
                        <Table>
                            <thead>
                                <tr>
                                    <th>
                                        {/* <div className="custom-control custom-checkbox">
                                            <input type='checkbox'
                                                className="custom-control-input"
                                                id="checkbox"
                                                name="checkbox"
                                                checked={allStudentList.length == allStudentListMaster.length}
                                            /> <label class="custom-control-label" for="checkbox"
                                                onClick={(e) => {
                                                    if (allStudentListIds.length !== 1) {

                                                        setAllStudentsList([allStudentListMaster[0]])
                                                        setAllStudentsListIds([allStudentListMaster[0].id])
                                                    } else {
                                                        //Add Teacher from all teacher list and update useState of all teacher list.
                                                        setAllStudentsListIds(allStudentMasterListIds)
                                                        setAllStudentsList(allStudentListMaster)
                                                    }
                                                }}
                                            >({allStudentList.length})</label>
                                        </div> */}
                                        <div className="custom-control custom-checkbox">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id="selectAllCheckbox"
                                                name="selectAll"
                                                checked={allStudentList.length === allStudentListMaster.length}
                                                onChange={(e) => {
                                                    if (allStudentListIds.length !== 1) {

                                                        setAllStudentsList([allStudentListMaster[0]])
                                                        setAllStudentsListIds([allStudentListMaster[0].id])
                                                    } else {
                                                        //Add Teacher from all teacher list and update useState of all teacher list.
                                                        setAllStudentsListIds(allStudentMasterListIds)
                                                        setAllStudentsList(allStudentListMaster)
                                                    }
                                                }}
                                            />
                                            <label className="custom-control-label" htmlFor="selectAllCheckbox">
                                                ({allStudentList.length})
                                            </label>
                                        </div>

                                    </th>
                                    <th>{props.t("Profile Photos")}</th>
                                    <th>{props.t("Name")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allStudentListMaster.map((val, key) => (
                                    <tr key={key}>
                                        <td>
                                            {/* <div className="custom-control custom-checkbox mb-2">
                                                <input type="checkbox"
                                                    class="custom-control-input"
                                                    id={`checkbox${val.id}`}
                                                    name="checkbox"
                                                    checked={allStudentListIds.includes(val.id)}
                                                />

                                                <label class="custom-control-label" for={`checkbox${val.id}`}
                                                    onClick={(e) => {

                                                        if (allStudentListIds.includes(val.id)) {
                                                            if (allStudentList.length != 1) {
                                                                let updatedStudentList = allStudentList.filter((student) => {
                                                                    return student.id != val.id
                                                                })
                                                                let updatedStudentListIds = allStudentListIds.filter((student) => {
                                                                    return student != val.id
                                                                })
                                                                setAllStudentsList(updatedStudentList)
                                                                setAllStudentsListIds(updatedStudentListIds)
                                                            }
                                                        } else {
                                                            let tempIds = [val.id, ...allStudentListIds]
                                                            setAllStudentsListIds(tempIds)
                                                            let tempStudentList = [];
                                                            for (const student of allStudentListMaster) {
                                                                if (tempIds.includes(student.id)) {
                                                                    tempStudentList.push(student)
                                                                }
                                                            }
                                                            setAllStudentsList(tempStudentList)
                                                        }

                                                    }}
                                                ></label>

                                            </div> */}
                                            <div className="custom-control custom-checkbox mb-2">
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    id={`checkbox${val.id}`}
                                                    name="checkbox"
                                                    checked={allStudentListIds.includes(val.id)}
                                                    onChange={(e) => {
                                                        if (allStudentListIds.includes(val.id)) {
                                                            if (allStudentList.length != 1) {
                                                                let updatedStudentList = allStudentList.filter((student) => {
                                                                    return student.id != val.id
                                                                })
                                                                let updatedStudentListIds = allStudentListIds.filter((student) => {
                                                                    return student != val.id
                                                                })
                                                                setAllStudentsList(updatedStudentList)
                                                                setAllStudentsListIds(updatedStudentListIds)
                                                            }
                                                        } else {
                                                            let tempIds = [val.id, ...allStudentListIds]
                                                            setAllStudentsListIds(tempIds)
                                                            let tempStudentList = [];
                                                            for (const student of allStudentListMaster) {
                                                                if (tempIds.includes(student.id)) {
                                                                    tempStudentList.push(student)
                                                                }
                                                            }
                                                            setAllStudentsList(tempStudentList)
                                                        }
                                                    }}
                                                />
                                                <label
                                                    className="custom-control-label"
                                                    htmlFor={`checkbox${val.id}`}
                                                ></label>
                                            </div>

                                        </td>
                                        <td>
                                            {
                                                !val.attributes.Photo ?
                                                    <div className="avatar-sm  mb-4">
                                                        <span className={"avatar-title rounded-circle bg-soft-primary text-primary font-size-16"}>
                                                            {val.attributes.Name.charAt(0).toUpperCase()}
                                                        </span>
                                                    </div>
                                                    :
                                                    <div className="avatar-sm  mb-4">
                                                        <img className="rounded-circle avatar-sm" src={val.attributes.Photo._url} alt="" />
                                                    </div>
                                            }
                                        </td>
                                        <td>{val.attributes.Name}
                                            <p className="text-muted mb-0">{getClassroomName(val.attributes.ClassRoomID)}</p></td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
}

const mapStatetoProps = (state) => {

    const { selectedKid, kids, kidMedicalObjects } = state.Kid;
    const { selectedSchool } = state.School;
    const { classrooms } = state.Classroom;
    const { parents } = state.Parent;


    return {
        selectedKid, kids, kidMedicalObjects,
        selectedSchool,
        classrooms,
        parents,
    };
};

export default withNamespaces()(
    withRouter(
        connect(mapStatetoProps, {
            getAKid,
            setSelecetdSchool,
            getClassroomsForSchoolId,
            getAllKids,
            getParentsForFamilyIds,
            getmedicalDetailsForKidIds,
        })(StudentIdCard)
    )
);